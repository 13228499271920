import PropTypes from "prop-types";
import React, { ReactElement } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = ({ location }): ReactElement => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <div style={{ fontSize: "75pt", textAlign: "center" }}>No Such Page</div>
  </Layout>
);

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotFoundPage;
